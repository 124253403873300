import _ from 'lodash'
import * as constants from './constants'
import * as selectors from './selectors'
import { getCurrentLanguage } from '../../../hooks/useTranslate'
import sdk from 'services/vigilanceHubService'

class NewsActions {
  fetchNews = ({ searchTerm = "", watchlist = false, usersWatchlist = [], newsType = "watchlistNews", size = 10 }) => ({
    type: newsType === "watchlistNews"? constants.FETCH_WATCHLIST_NEWS: (newsType === "watchlistLatestWidget"? constants.FETCH_LATEST_WATCHLIST_NEWS: constants.FETCH_LATEST_PRODUCT_NEWS),
    searchTerm,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(
        config,
        'websiteConfig.vigilanceHubOrganisationId'
      )
      const newsTag = _.get(config, 'websiteConfig.newsTag', '')
      const language = getCurrentLanguage() || null

      let params = {
        organisationId: organisationId,
        page: 1,
        size,
        language,
        tags: newsTag,
        watchlist,
        searchTerm
      }

      if (usersWatchlist.length > 0) {
        params.usersWatchlist = JSON.stringify(usersWatchlist)
      } 

      return sdk.news.fetchNews(params)
    },
  })

  fetchNewsNext = ({ searchTerm = "", watchlist = false, usersWatchlist = [], newsType = "watchlistNews", size = 10 }) => ({
    type: newsType === "watchlistNews"? constants.FETCH_WATCHLIST_NEWS_NEXT: (newsType === "watchlistLatestWidget"? constants.FETCH_LATEST_WATCHLIST_NEWS_NEXT: constants.FETCH_LATEST_PRODUCT_NEWS_NEXT),
    searchTerm,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(
        config,
        'websiteConfig.vigilanceHubOrganisationId'
      )
      const newsTag = _.get(config, 'websiteConfig.newsTag', '')
      const page = newsType === "watchlistNews"? selectors.getWatchlistPage(getState()): (newsType === "watchlistLatestWidget"? selectors.getLatestWatchlistPage(getState()): selectors.getLatestProductPage(getState()))
      const language = getCurrentLanguage() || null

      let params = {
        organisationId: organisationId,
        page: page + 1,
        size,
        language,
        tags: newsTag,
        watchlist,
        searchTerm
      }

      if (usersWatchlist.length > 0) {
        params.usersWatchlist = JSON.stringify(usersWatchlist)
      } 

      return sdk.news.fetchNews(params)
    },
  })

  getWatchlistNewsArticle = ({ id }) => ({
    type: newsType === "watchlistNews"? constants.GET_WATCHLIST_NEWS_ARTICLE: (newsType === "watchlistLatestWidget"? constants.GET_LATEST_WATCHLIST_NEWS_ARTICLE: constants.GET_LATEST_PRODUCT_NEWS_ARTICLE),
    payload: { id },
    promise: (dispatch, getState) => {
      return sdk.news.fetchNewsArticleById({ id })
    },
  })

  resetStore = (newsType = "watchlistNews") => ({
    type: newsType === "watchlistNews"? constants.RESET_WATCHLIST_STORE: (newsType === "watchlistLatestWidget"? constants.RESET_LATEST_WATCHLIST_STORE: constants.RESET_LATEST_PRODUCT_STORE),
  })
}

export default new NewsActions()
