import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  watchlistNews: {
    size: 10,
    page: 1,
    language: undefined,
    error: undefined,
    total: undefined,
    status: undefined,
    results: []
  },
  watchlistLatestWidget: {
    size: 2,
    page: 1,
    language: undefined,
    error: undefined,
    total: undefined,
    status: undefined,
    results: []
  },
  productLatestWidget: {
    size: 2,
    page: 1,
    language: undefined,
    error: undefined,
    total: undefined,
    status: undefined,
    results: []
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_WATCHLIST_STORE:
      return defaultState.watchlistNews
    case constants.RESET_LATEST_WATCHLIST_STORE:
      return defaultState.watchlistLatestWidget
    case constants.RESET_LATEST_PRODUCT_STORE:
      return defaultState.productLatestWidget
    case constants.FETCH_WATCHLIST_NEWS:
      return reducers.fetchWatchlistNews(state, action)
    case constants.FETCH_LATEST_WATCHLIST_NEWS:
      return reducers.fetchLatestWatchlistNews(state, action)
    case constants.FETCH_LATEST_PRODUCT_NEWS:
      return reducers.fetchLatestProductNews(state, action)
    case constants.FETCH_WATCHLIST_NEWS_NEXT:
      return reducers.fetchWatchlistNewsNext(state, action)
    case constants.FETCH_LATEST_WATCHLIST_NEWS_NEXT:
      return reducers.fetchLatestWatchlistNewsNext(state, action)
    case constants.FETCH_LATEST_PRODUCT_NEWS_NEXT:
      return reducers.fetchLatestProductNewsNext(state, action)
    case constants.GET_WATCHLIST_NEWS_ARTICLE:
      return reducers.getWatchlistNewsArticle(state, action)
    case constants.GET_LATEST_WATCHLIST_NEWS_ARTICLE:
      return reducers.getLatestWatchlistNewsArticle(state, action)
    case constants.GET_LATEST_PRODUCT_NEWS_ARTICLE:
      return reducers.getLatestProductNewsArticle(state, action)
    default:
      return state
  }
}
