import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class NewsReducers {
  fetchWatchlistNews (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          watchlistNews: {
            total,
            page: 1,
            language,
            results,
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchLatestWatchlistNews (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          watchlistLatestWidget: {
            total,
            page: 1,
            language,
            results,
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchLatestProductNews (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          productLatestWidget: {
            total,
            page: 1,
            language,
            results,
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchWatchlistNewsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const { results } = state.watchlistNews
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total') || 0
        const language = _.get(action, 'result.language')
        return {
          ...state,
          watchlistNews: {
            total,
            page: state.watchlistNews.page + 1,
            language,
            results: [ ...results, ...newResults ],
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchLatestWatchlistNewsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const { results } = state.watchlistLatestWidget
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total') || 0
        const language = _.get(action, 'result.language')
        return {
          ...state,
          watchlistLatestWidget: {
            total,
            page: state.watchlistLatestWidget.page + 1,
            language,
            results: [ ...results, ...newResults ],
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchLatestProductNewsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const { results } = state.productLatestWidget
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total') || 0
        const language = _.get(action, 'result.language')
        return {
          ...state,
          productLatestWidget: {
            total,
            page: state.productLatestWidget.page + 1,
            language,
            results: [ ...results, ...newResults ],
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  getWatchlistNewsArticle = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            articles: {
              ...state.articles,
              [action.payload.id]: action.result
            },
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          watchlistNews: {
            ...state.watchlistNews,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  getLatestWatchlistNewsArticle = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            articles: {
              ...state.articles,
              [action.payload.id]: action.result
            },
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          watchlistLatestWidget: {
            ...state.watchlistLatestWidget,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  getLatestProductNewsArticle = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            articles: {
              ...state.articles,
              [action.payload.id]: action.result
            },
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          productLatestWidget: {
            ...state.productLatestWidget,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }


}

export default new NewsReducers()
