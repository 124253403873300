import { createSelector } from 'reselect'

import { PENDING } from '../../reduxPromise'
import { FETCH_WATCHLIST_NEWS_NEXT, FETCH_LATEST_WATCHLIST_NEWS_NEXT, FETCH_LATEST_PRODUCT_NEWS_NEXT } from './constants'

const getLatestProductStatus = state => state.newsSdk.productLatestWidget?.status
const getLatestProductAction = state => state.newsSdk.productLatestWidget?.action
const getLatestWatchlistStatus = state => state.newsSdk.watchlistLatestWidget?.status
const getLatestWatchlistAction = state => state.newsSdk.watchlistLatestWidget?.action
const getWatchlistStatus = state => state.newsSdk.watchlistNews?.status
const getWatchlistAction = state => state.newsSdk.watchlistNews?.action

export const getLatestProductResults = state => state.newsSdk.productLatestWidget?.results
export const getLatestProductTotal = state => state.newsSdk.productLatestWidget?.total
export const getLatestProductError = state => state.newsSdk.productLatestWidget.error
export const getLatestProductSize = state => state.newsSdk.productLatestWidget.size
export const getLatestProductPage = state => state.newsSdk.productLatestWidget.page
export const getLatestProductLanguage = state => state.newsSdk.productLatestWidget.language
export const getLatestProductSelectedArticles = state => state.newsSdk.productLatestWidget.articles

export const getLatestWatchlistResults = state => state.newsSdk.watchlistLatestWidget?.results
export const getLatestWatchlistTotal = state => state.newsSdk.watchlistLatestWidget?.total
export const getLatestWatchlistError = state => state.newsSdk.watchlistLatestWidget.error
export const getLatestWatchlistSize = state => state.newsSdk.watchlistLatestWidget.size
export const getLatestWatchlistPage = state => state.newsSdk.watchlistLatestWidget.page
export const getLatestWatchlistLanguage = state => state.newsSdk.watchlistLatestWidget.language
export const getLatestWatchlistSelectedArticles = state => state.newsSdk.watchlistLatestWidget.articles

export const getWatchlistResults = state => state.newsSdk.watchlistNews?.results
export const getWatchlistTotal = state => state.newsSdk.watchlistNews?.total
export const getWatchlistError = state => state.newsSdk.watchlistNews.error
export const getWatchlistSize = state => state.newsSdk.watchlistNews.size
export const getWatchlistPage = state => state.newsSdk.watchlistNews.page
export const getWatchlistLanguage = state => state.newsSdk.watchlistNews.language
export const getWatchlistSelectedArticles = state => state.newsSdk.watchlistNews.articles

export const getLatestProductIsLoading = createSelector(
  getLatestProductStatus,
  status => status === PENDING
)

export const getLatestProductIsFetchMore = createSelector([
  getLatestProductAction, getLatestProductStatus
], (action, status) => status === PENDING && action === FETCH_LATEST_PRODUCT_NEWS_NEXT)

export const getLatestProductHasMore = createSelector([
  getLatestProductTotal, getLatestProductResults
], (total, results) => total !== null && results?.length < total
)

export const getLatestProductArticleById = ({ id }) => createSelector([
  getLatestProductSelectedArticles
], (articles) => {
  return articles[id]
})

export const getLatestWatchlistIsLoading = createSelector(
  getLatestWatchlistStatus,
  status => status === PENDING
)

export const getLatestWatchlistIsFetchMore = createSelector([
  getLatestWatchlistAction, getLatestWatchlistStatus
], (action, status) => status === PENDING && action === FETCH_LATEST_WATCHLIST_NEWS_NEXT)

export const getLatestWatchlistHasMore = createSelector([
  getLatestWatchlistTotal, getLatestWatchlistResults
], (total, results) => total !== null && results?.length < total
)

export const getLatestWatchlistArticleById = ({ id }) => createSelector([
  getLatestWatchlistSelectedArticles
], (articles) => {
  return articles[id]
})

export const getWatchlistIsLoading = createSelector(
  getWatchlistStatus,
  status => status === PENDING
)

export const getWatchlistIsFetchMore = createSelector([
  getWatchlistAction, getWatchlistStatus
], (action, status) => status === PENDING && action === FETCH_WATCHLIST_NEWS_NEXT)

export const getWatchlistHasMore = createSelector([
  getWatchlistTotal, getWatchlistResults
], (total, results) => total !== null && results?.length < total)

export const getWatchlistArticleById = ({ id }) => createSelector([
  getWatchlistSelectedArticles
], (articles) => {
  return articles[id]
})


