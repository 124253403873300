export const FETCH_WATCHLIST_NEWS = 'newsSdk/FETCH_WATCHLIST_NEWS'
export const FETCH_LATEST_WATCHLIST_NEWS = 'newsSdk/FETCH_LATEST_WATCHLIST_NEWS'
export const FETCH_LATEST_PRODUCT_NEWS = 'newsSdk/FETCH_LATEST_PRODUCT_NEWS'
export const FETCH_WATCHLIST_NEWS_NEXT = 'newsSdk/FETCH_WATCHSLIST_NEWS_NEXT'
export const FETCH_LATEST_WATCHLIST_NEWS_NEXT = 'newsSdk/FETCH_LATEST_WATCHSLIST_NEWS_NEXT'
export const FETCH_LATEST_PRODUCT_NEWS_NEXT = 'newsSdk/FETCH_LATEST_PRODUCT_NEWS_NEXT'
export const RESET_WATCHLIST_STORE = 'newsSdk/RESET_WATCHLIST_STORE'
export const RESET_LATEST_WATCHLIST_STORE = 'newsSdk/RESET_LATEST_WATCHLIST_STORE'
export const RESET_LATEST_PRODUCT_STORE = 'newsSdk/RESET_LATEST_PRODUCT_STORE'
export const GET_WATCHLIST_NEWS_ARTICLE = 'newsSdk/GET_WATCHLIST_NEWS_ARTICLE'
export const GET_LATEST_WATCHLIST_NEWS_ARTICLE = 'newsSdk/GET_LATEST_WATCHLIST_NEWS_ARTICLE'
export const GET_LATEST_PRODUCT_NEWS_ARTICLE = 'newsSdk/GET_LATEST_PRODUCT_NEWS_ARTICLE'
